<app-markdown>
  <div data-ref="content"></div>
  <script>
    import marked from 'marked'

    export default {
      renderMarkdown(){
        this.$('[data-ref="content"]').innerHTML = marked(this.props.content)
      },
      onMounted(){
        this.renderMarkdown()
      },
      onUpdated(){
        this.renderMarkdown()
      }
    }
  </script>
</app-markdown>