<app-site>
  <div class="o-container">
    <div class="o-flow-layout c-site-layout">
      <header class="o-width-fixed c-header-block">
        <h1 class="c-logo" if="{ state.pathArray[1] === '' }">
          <a href="/" onclick="{ route }"><img src="/img/g.svg" alt="g"></a>
        </h1>
        <h2 class="c-logo" if="{ state.pathArray[1] != '' }">
          <a href="/" onclick="{ route }"><img src="/img/g.svg" alt="g"></a>
        </h2>
      </header>
      <main class="o-width-fill">
        <div class="o-stack-layout">
          <template if="{ state.pathArray[1] === '' && state.posts}">
            <article each="{ post in state.posts }">
              <h2><a href="/posts/{ post._id }" onclick="{ route }">{ post.title }</a></h2>
              <p class="c-post-date">
                created:{ getDate(post._created) }
                { post._modified ? ` updated: ${getDate(post._modified)}` : null }
              </p>
            </article>
          </template>
          <template if="{ state.pathArray[1] === 'posts' && state.posts }">
            <article>
              <div class="o-stack-layout">
                <div>
                  <h1>{ state.single.title }</h1>
                  <p class="c-post-date">
                    created:{ getDate(state.single._created) }
                    { state.single._modified ? `updated: ${getDate(state.single._modified)}` : null }
                  </p>
                </div>
                <div>
                  <app-markdown content="{ state.single.content }"></app-markdown>
                </div>
              </div>
            </article>
            <p class="c-back-link"><a href=" /" onclick="{ route }">⇦ Back</a></p>
          </template>
        </div>
      </main>
    </div>
  </div>
  <script>
    export default {
      state: {
        pathArray: [],
        siteTitle: 'g',
      },
      onBeforeMount() {
        this.initGoogleAnalytics()
        window.addEventListener('popstate', (e) => {
          this.updatePathArray()
          this.update()
        })
        this.updatePathArray()
        this.fetchPosts()
      },
      onBeforeUpdate() {
        if (this.state.pathArray[1] === 'posts' && this.state.posts) {
          this.setSinglePost(this.state.pathArray[2])
        }else{
          window.document.title = this.state.siteTitle
        }
      },
      route(e) {
        this.pushHistory(e)
        this.updatePathArray()
        this.update()
      },
      updatePathArray() {
        this.state.pathArray = this.pathNameArray()
      },
      fetchPosts() {
        fetch('/cockpit/api/collections/get/posts?token=account-b1184c6e9d9e4d8fd2f4b1becb0dab', {
          method: 'post',
          credentials: "same-origin",
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            filter: { published: true },
            sort: { _created: -1 },
          })
        })
          .then(result => result.json())
          .then(result => {
            this.state.posts = result.entries
            this.update()
          })
          .catch(error => console.error(error))
      },
      setSinglePost(id) {
        this.state.single = this.state.posts.filter(post => {
          return post._id === id
        })[0]
        window.document.title = `${this.state.single.title} | ${this.state.siteTitle}`
      },
      getDate(s) {
        const date = new Date(s * 1000)
        return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
      },
      initGoogleAnalytics() {
        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', 'G-KH5PF9E46Z');
      }
    }
  </script>
</app-site>