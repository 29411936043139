import { register, mount, install, update } from 'riot'

import AppSite from './components/app-site.js'
register('app-site', AppSite)

import RawHtml from './components/raw-html.js'
register('raw-html', RawHtml)

import AppMarkdown from './components/app-markdown.js'
register('app-markdown', AppMarkdown)

install(component => {
  component.pathNameArray = () => {
    return location.pathname.split('/')
  }
  component.pushHistory = (e) => {
    let inspectedElm = e.target
    while (!inspectedElm.href) {
      inspectedElm = inspectedElm.parentNode
    }
    if (inspectedElm.href.match(location.origin).length) {
      e.preventDefault()
      history.pushState({}, '', inspectedElm.href)
    }
  }
  return component
})

mount('[data-riot]')